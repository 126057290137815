import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';

import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import BrandCTA from 'components/BrandCta';
import AlertBox from 'components/Content/AlertBox';
import { HangingMary } from 'components/Content/HangingMary';
import ExternalLink from 'components/ExternalLink';

import bottle1 from '../../../assets/images/bariactiv-bottle1.png';
import bottle2 from '../../../assets/images/bariactiv-bottle2.png';
import logoAsmbs from '../../../assets/images/asmbs-logo.png';
import imgBottle1 from '../../../assets/images/bottle-icon1.png';
import imgBottle2 from '../../../assets/images/bottle-icon2.png';
import NDHCPBariactivHeroMobile from '../../../assets/images/ND-HCP-bariactivSupplements-mobile.png';
import WLSchart from '../../../assets/images/chart-wls-supplements.png';

import NDchewablesPDF from '../../../../static/pdfs/NS-05460_Nutrition_Direct_Chewable_BariActiv_Leave_Behind_-_Digital_.pdf';
import NDtabletsPDF from '../../../../static/pdfs/NS-05461_Nutrition_Direct_Tablet_BariActiv_LeaveBehind_Digital.pdf';

import './bariactiv.scss';

let Subscription = null;

const calciumTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Calcium Chew Facts',
};

const multiChewTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Multi Chew Facts',
};

const ironChewTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Iron Chew Facts',
};

const calciumChewTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Calcium Tab Facts',
};

const multiTabTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Multi Tab Facts',
};

const ironTabTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Iron Tab Facts',
};

const bariactivPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionHcp;
  // const metaData = appConfigs?.metaData?.nutritionHcp;

  const brandNames = {
    nascobal: appConfigs?.metaData?.brandNames?.nascobal,
    bariactiv: appConfigs?.metaData?.brandNames?.bariactiv,
    nutrition: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | BariActiv® Supplements';
  const pageDescription = 'Designed to meet the ASMBS nutritional guidelines';

  const renderBariactiv = () => {
    return (
      <div>
        <ContentBlock
          id="bariactiv-block"
          className="no-padding-top margin-offset"
        >
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    <span
                      className="brandname-bariactiv txt-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    SUPPLEMENTS
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={NDHCPBariactivHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock white id="colorblock-bariactiv">
              <Row>
                <Col xs={12}>
                  <h2 className="strong blue" style={{ marginBottom: 30 }}>
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    supplements: 2 formulations designed to meet ASMBS
                    nutritional guidelines*
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2}>
                  <img src={bottle1} alt="bariactiv-bottle" />
                </Col>
                <Col xs={12} md={4}>
                  <h2>
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    <span className="orange">Chewable:</span>
                  </h2>
                  <ul>
                    <li>Can be taken immediately following surgery</li>
                    <li>Also good for lifelong nutritional support</li>
                    <li>Great-tasting fruit flavor</li>
                  </ul>
                </Col>
                <Col xs={12} md={2}>
                  <img src={bottle2} alt="bariactiv-bottle" />
                </Col>
                <Col xs={12} md={4}>
                  <h2>
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    <span className="orange">Tablets/Capsules:</span>
                  </h2>
                  <ul>
                    <li>For patients who no longer require chewable tablets</li>
                    <li>
                      Upon healthcare provider recommendation, patients may
                      switch to tablets/capsules by calling ProCare PharmacyCare
                      at <nobr>1-855-828-1488</nobr>
                    </li>
                  </ul>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white id="colorblock-lightorange-table">
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    <span
                      className="brandname-nutrition"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nutrition,
                      }}
                    />
                    : Designed specifically for bariatric patients
                    <sup>1&dagger;</sup>
                  </h2>
                  <img src={WLSchart} />

                  <p className="footnote bottom-spacer">
                    BPD/DS=biliopancreatic diversion/duodenal switch; d=per day;
                    DFE=dietary folate equivalent; F=female; mo=month;
                    SL=sublingual; WLS=weight loss surgery.
                  </p>
                  <img src={logoAsmbs} alt="asmbs-logo" />
                  <p className="footnote asterik top-spacer">
                    ASMBS Integrated Health Nutritional Guidelines for the
                    Surgical Weight Loss Patient — 2016 Update: Micronutrients.
                  </p>
                  <p className="footnote dagger">
                    For all types of surgery, including Roux-en-Y gastric bypass
                    (RYGB), laparoscopic sleeve gastrectomy (LSG), and
                    laparoscopic adjustable gastric banding (LAGB).
                  </p>
                  <p className="footnote double-dagger">
                    <strong>
                      Calcium is provided in the BariActiv<sup>&reg;</sup>{' '}
                      Calcium (1,200 mg per day) + Vitamin D (3,000 IU per day)
                      supplement. It is very important NOT to take calcium and
                      multivitamin with iron supplements at the same time. Take
                      them at least 2 hours apart.
                    </strong>
                  </p>
                  <p className="footnote section">
                    Equivalent to 10,000 IU/d (3,000 mcg RAE/d) retinol.
                  </p>
                  <p className="footnote double-lines bottom-spacer">
                    Equivalent to 3,000 IU/d.
                  </p>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white id="colorblock-white-icon">
              <Row>
                <Col xs={12} md={6}>
                  <img
                    src={imgBottle1}
                    style={{ marginBottom: 0 }}
                    alt="bariactiv-bottle tablet-capsul-baractiv-bottle"
                  />
                  <h2>
                    BariActiv<sup>&reg;</sup> Chewable{' '}
                    <br className="hide-only-mobile" /> Nutritional Information
                  </h2>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Calcium + D<sub>3</sub> &amp; Magnesium Chewable
                      <sup>&para;</sup>
                    </span>
                    <span className="prod-direction">
                      Take one serving (2 tablets) twice a day
                    </span>
                    <ExternalLink
                      href={NDchewablesPDF}
                      className="prod-link"
                      gaTracking={calciumTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin Chewable
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 tablet) once a day
                    </span>
                    <ExternalLink
                      href={NDchewablesPDF}
                      className="prod-link"
                      gaTracking={multiChewTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin + Iron Chewable<sup>&para;</sup>
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 tablet) once a day
                    </span>
                    <ExternalLink
                      href={NDchewablesPDF}
                      className="prod-link"
                      gaTracking={ironChewTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <img
                    src={imgBottle2}
                    style={{ marginBottom: 0 }}
                    alt="bariactiv-bottle tablet-capsul-baractiv-bottle"
                  />
                  <h2>
                    BariActiv<sup>&reg;</sup> Tablets/Capsules{' '}
                    <br className="hide-only-mobile" />
                    Nutritional Information
                  </h2>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Calcium + D<sub>3</sub> &amp; Magnesium Tablets
                      <sup>&para;</sup>
                    </span>
                    <span className="prod-direction">
                      Take one serving (2 tablets) twice a day
                    </span>
                    <ExternalLink
                      href={NDtabletsPDF}
                      className="prod-link"
                      gaTracking={calciumChewTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin Capsule
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 capsule) once a day
                    </span>
                    <ExternalLink
                      href={NDtabletsPDF}
                      className="prod-link"
                      gaTracking={multiTabTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin + Iron Capsule<sup>&para;</sup>
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 capsule) once a day
                    </span>
                    <ExternalLink
                      href={NDtabletsPDF}
                      className="prod-link"
                      gaTracking={ironTabTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <AlertBox>
                    <HangingMary
                      className=""
                      symbol="<sup>&para;</sup>"
                      text="It is very important NOT to take calcium and multivitamin with iron supplements at the same time. Take them at least 2 hours apart."
                    />
                  </AlertBox>
                </Col>
              </Row>
            </ColorBlock>
          </Grid>
        </ContentBlock>
        <Row>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/vitaminb12/hcp/request_a_sales_rep"
              LinkCaption="REQUEST A REPRESENTATIVE"
            >
              REQUEST A REPRESENTATIVE
            </BrandCTA>
          </Col>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/hcp/practice-resources"
              LinkCaption="DISCOVER PRACTICE RESOURCES"
            >
              DISCOVER PRACTICE RESOURCES
            </BrandCTA>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-hcp-bariactiv">
        {renderBariactiv()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="nutrition-hcp-bariactiv-page">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default bariactivPage;
